import axios from "axios";

export const loginReporting = async (data) => {

    try {
        const response = await axios.post('https://dev.france-valley.paris/api/pageReportings/login', data, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const checkLogin = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/pageReportings/logged", { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const downloadFileReporting = async (fileRelativePath) => {
    try {
        const encodedFilePath = encodeURIComponent(fileRelativePath);
        const response = await axios.get(`https://dev.france-valley.paris/api/pageReportings/${encodedFilePath}`, {
            responseType: "blob",
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getReportings = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/pageReportings/reportings/" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}