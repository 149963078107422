import axios from "axios";

export const downloadFile = async (fileRelativePath) => {
    try {
        const encodedFilePath = encodeURIComponent(fileRelativePath);
        const response = await axios.get(`https://dev.france-valley.paris/api/downloadsFiles/${encodedFilePath}`, {
            responseType: "blob",
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getReportingsByUser = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/reportings/byUser" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getLastReportingsByClient = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/reportings/last/client" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getLastReportingsByPartner = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/reportings/last/partner" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
