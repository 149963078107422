import axios from "axios";

export const getAllFondsActive = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/funds/all/active" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getFondsByRoleWithEntranceFee = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/funds/role/linkEntranceFee" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getFondsREsubscribeByRoleWithEntranceFee = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/funds/resubscribe/role/linkEntranceFee" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getFondsEditSubscribeByRoleWithEntranceFee = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/funds/editSubscribe/role/linkEntranceFee" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/*export const getFondById = async (fondId) => {
    try {
        const response = await axios.get(`http://127.0.0.1:3001/api/subscribes/funds/${fondId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}*/


export const getSyntheseFonds = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/synthesefunds/" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
