import axios from "axios";

export const getSimulations = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/simulations" , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/*export const getSimulationById = async (simulationId) => {
    try {
        const response = await axios.get(`http://127.0.0.1:3001/api/subscribes/partner/${simulationId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}*/

