import axios from "axios";

export const getDocumentsAGByProduitAGId = async (produitAGId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/documentsAG/produitAG/${produitAGId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

