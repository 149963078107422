import React from "react";
import {Button} from "react-bootstrap";
import { Card } from 'react-bootstrap';
import formatDate from "./FormatDate";
import '../css/CardArticle.css';

function CardArticle(props) {
    const {article} = props;

    const handleClickMore = () => {
        window.open(article.urlArticle, '_blank');
    };

    return (
        <>
            <Card key={article.id} className="mb-5 h-100 shadow">
                <Card.Img variant="top"  style={{
                        width: '100%',
                        height: '200px',
                        objectFit: 'cover'
                    }} src={article.urlImage} />
                <Card.Body className="d-flex flex-column">
                    <Card.Title style={{ color: "#243E45" }}>{article.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{formatDate(article.date)}</Card.Subtitle>
                    <Card.Text className="flex-grow-1">{article.message}</Card.Text>
                    <Button className="btn-custom" onClick={handleClickMore}>Lire la suite</Button>
                </Card.Body>
            </Card>
        </>
    );
}

export default CardArticle;
