import React, { useState, useEffect } from "react";
import {Tab, Row, Col, Container, Card, Table, Form, Button} from "react-bootstrap";
import { saveAs } from "file-saver";
import '../css/Reportings.css';
import Spinner from "react-bootstrap/Spinner";
import { checkLogin, downloadFileReporting, getReportings, loginReporting } from "../services/PageReportings";
import { FiEye, FiEyeOff } from "react-icons/fi";
import FormatDate from "../components/FormatDate";

function Reporting() {

    const [reportings, setReportings] = useState([]);
    const [filteredReportings, setFilteredReportings] = useState([]);
    const [selectedType, setSelectedType] = useState("");
    const [selectedAssetClass, setSelectedAssetClass] = useState("");
    const [selectedFunds, setSelectedFunds] = useState("");
    const [selectedYear, setSelectedYear] = useState("");

    const [filteredTypes, setFilteredTypes] = useState([]);
    const [filteredAssetClasses, setFilteredAssetClasses] = useState([]);
    const [filteredFunds, setFilteredFunds] = useState([]);
    const [filteredYears, setFilteredYears] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingLogin(true);
        try {
            const response = await loginReporting({
                password,
            });
            if (response.status === 200) {
                setAuthenticated(true);
            } 
        } catch (error) {
            setErrorMessage(error.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        } finally {
            setIsLoadingLogin(false); // Fin du chargement (succès ou échec)
        }
      };

      const handleInputChange = () => {
        setErrorMessage("");
    };

    useEffect(() => {
        
        async function getProductLoad() {
            const checkLog = await checkLogin();
            if (checkLog.checkLogin === true) {
                setAuthenticated(true);
            }
            if (authenticated === true) {
                const reportings = await getReportings();
                setReportings(reportings);
            }
            setIsLoading(false);
        }
        getProductLoad();
    }, [authenticated]);

    useEffect(() => {
        // Filtrer les reportings en fonction des filtres appliqués
        const filtered = reportings.filter(reporting =>
            (selectedType === "" || reporting.typeDoc === selectedType) &&
            (selectedAssetClass === "" || reporting.assetClass === selectedAssetClass) &&
            (selectedFunds === "" || reporting.fundName === selectedFunds) &&
            (selectedYear === "" || reporting.year.toString() === selectedYear)
        );
    
        setFilteredReportings(filtered);
    
        // Compter les éléments pour chaque filtre indépendamment
        const countByType = {};
        const countByAssetClass = {};
        const countByFund = {};
        const countByYear = {};
    
        reportings.forEach(reporting => {
            // Compter le nombre de documents pour chaque type indépendamment des autres filtres
            if (
                (selectedAssetClass === "" || reporting.assetClass === selectedAssetClass) &&
                (selectedFunds === "" || reporting.fundName === selectedFunds) &&
                (selectedYear === "" || reporting.year.toString() === selectedYear)
            ) {
                countByType[reporting.typeDoc] = (countByType[reporting.typeDoc] || 0) + 1;
            }
    
            // Compter le nombre de documents pour chaque classe d'actif indépendamment des autres filtres
            if (
                (selectedType === "" || reporting.typeDoc === selectedType) &&
                (selectedFunds === "" || reporting.fundName === selectedFunds) &&
                (selectedYear === "" || reporting.year.toString() === selectedYear)
            ) {
                countByAssetClass[reporting.assetClass] = (countByAssetClass[reporting.assetClass] || 0) + 1;
            }
    
            // Compter le nombre de documents pour chaque fonds indépendamment des autres filtres
            if (
                (selectedType === "" || reporting.typeDoc === selectedType) &&
                (selectedAssetClass === "" || reporting.assetClass === selectedAssetClass) &&
                (selectedYear === "" || reporting.year.toString() === selectedYear)
            ) {
                countByFund[reporting.fundName] = (countByFund[reporting.fundName] || 0) + 1;
            }
    
            // Compter le nombre de documents pour chaque année indépendamment des autres filtres
            if (
                (selectedType === "" || reporting.typeDoc === selectedType) &&
                (selectedAssetClass === "" || reporting.assetClass === selectedAssetClass) &&
                (selectedFunds === "" || reporting.fundName === selectedFunds)
            ) {
                countByYear[reporting.year] = (countByYear[reporting.year] || 0) + 1;
            }
        });
    
        // Mettre à jour les options de chaque filtre avec le nombre de reportings disponibles
        setFilteredTypes([...new Set(reportings.map(r => r.typeDoc))].map(type => ({
            value: type,
            label: `${type} (${countByType[type] || 0})`
        })));
    
        setFilteredAssetClasses([...new Set(reportings.map(r => r.assetClass))].map(assetClass => ({
            value: assetClass,
            label: `${assetClass} (${countByAssetClass[assetClass] || 0})`
        })));
    
        setFilteredFunds([...new Set(reportings.map(r => r.fundName))].map(fund => ({
            value: fund,
            label: `${fund} (${countByFund[fund] || 0})`
        })));
    
        setFilteredYears([...new Set(reportings.map(r => r.year))].map(year => ({
            value: year,
            label: `${year} (${countByYear[year] || 0})`
        })));
    }, [selectedType, selectedAssetClass, selectedFunds, selectedYear, reportings]);
    




    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFileReporting(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            throw error;
        }
    };

    if (!authenticated) {
        return (
            <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div
                style={{
                    padding: "40px 50px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    maxWidth: "500px",
                    width: "100%",
                    boxSizing: "border-box",
                }}
            >
                <form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    onSubmit={handleSubmit}
                >
                    <h2 style={{ marginBottom: "30px" }}>
                        Reportings
                    </h2>
                    <img
                        src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                        alt="Logo"
                        style={{ maxWidth: "100%", marginBottom: "30px" }}
                    />
                    <>
                        <div
                            style={{
                                position: 'relative',
                                marginBottom: '20px',
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >
                            <input
                                style={{
                                    padding: "15px",
                                    width: "100%",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    paddingRight: '40px',
                                }}
                                type={showPassword ? "text" : "password"}
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    handleInputChange();
                                }}
                                required
                            />
                            <button
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: "#aaa"
                                }}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <FiEye /> : <FiEyeOff /> }
                            </button>
                        </div>
                    </>
                    {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <button
                        style={{
                            backgroundColor: "#0066CC",
                            color: "#fff",
                            padding: "15px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            width: "100%",
                        }}
                        
                        type="submit"
                        disabled={isLoadingLogin} // Désactiver le bouton pendant le chargement
                    >
                        {isLoadingLogin ? (
                            <span className="spinner-border spinner-border-sm me-2" type="submit" role="status" aria-hidden="true"></span>
                        ) : (
                            "Se connecter"
                        )}
                    </button>
                </form>
            </div>
        </div>
        );
      }

    return (
        <Container>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
            <>
                <Container className="my-5">
                    <h1 className="text-center mb-4" style={{color: '#092A6C'}}>Reportings</h1>
                    <Card className="shadow mb-5">
                        <Card.Body>
                        <Row className="mb-3">
                                <Col>
                                    <Form.Label>Type de document :</Form.Label>
                                    <Form.Select
                                        value={selectedType}
                                        onChange={(e) => setSelectedType(e.target.value)}
                                        aria-label="Filtrer par type de document"
                                        className={selectedType ? "bold-selected" : ""}
                                    >
                                        <option value="">Tous les types de document</option>
                                        {filteredTypes.map(type => (
                                            <option key={type.value} value={type.value}>
                                                {type.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Classe d'actif :</Form.Label>
                                    <Form.Select
                                        value={selectedAssetClass}
                                        onChange={(e) => setSelectedAssetClass(e.target.value)}
                                        aria-label="Filtrer par classe d'actif"
                                    >
                                        <option value="">Toutes les classes d'actif</option>
                                        {filteredAssetClasses.map(assetClass => (
                                            <option key={assetClass.value} value={assetClass.value} className={selectedAssetClass === assetClass.value ? "font-weight-bold" : ""}
                                            >
                                                {assetClass.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Fonds :</Form.Label>
                                    <Form.Select
                                        value={selectedFunds}
                                        onChange={(e) => setSelectedFunds(e.target.value)}
                                        aria-label="Filtrer par fonds"
                                    >
                                        <option value="">Tous les fonds</option>
                                        {filteredFunds.map(fund => (
                                            <option key={fund.value} value={fund.value} className={selectedFunds === fund.value ? "font-weight-bold" : ""}>
                                                {fund.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Année :</Form.Label>
                                    <Form.Select
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        aria-label="Filtrer par année"
                                    >
                                        <option value="">Toutes les années</option>
                                        {filteredYears.map(year => (
                                            <option key={year.value} value={year.value} className={selectedYear === year.value ? "font-weight-bold" : ""}>
                                                {year.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="shadow">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                    <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    <Table className="table table-striped table-bordered table-hover table-sm m-0">
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th>Nom</th>
                                                                <th>Classe d'actif</th>
                                                                <th>Document</th>
                                                                <th>Fonds</th>
                                                                <th>Année</th>
                                                                <th>Date de publication</th>
                                                                <th>Télécharger</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {filteredReportings && filteredReportings.length > 0 && (
                                                                filteredReportings.map((reporting) => (
                                                                <tr key={reporting.id} className="align-middle">
                                                                    <td className="text-center">{reporting.name}</td>
                                                                    <td className="text-center">{reporting.assetClass}</td>
                                                                    <td className="text-center">{reporting.typeDoc}</td>
                                                                    <td className="text-center">{reporting.fundName}</td>
                                                                    <td className="text-center">{reporting.year}</td>
                                                                    <td className="text-center">{FormatDate(reporting.publicationDate)}</td>
                                                                    <td className="text-center"><Button className="btn-custom mr-2" onClick={() => handleDownload(reporting.pathDoc, reporting.name)}>Télécharger</Button></td>
                                                                </tr>
                                                        )))}
                                                        </tbody>
                                                    </Table>
                                                    </div>
                                                </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Container>
            </>
            )}
        </Container>
    );

}

export default Reporting;
