import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Modal, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import { getResolutionsAGByProduitAGId } from "../services/resolutionAG";
import { getProduitAGById } from "../services/ProduitAG";
import "../css/ResolutionAG.css";
import { voteAG } from "../services/VoteAG";


function ResolutionAG() {
    const [isLoading, setIsLoading] = useState(true);
    const [produitAG, setProduitAG] = useState({});
    const [resolutionsAG, setResolutionsAG] = useState([]);
    const [votes, setVotes] = useState({});
    const [expandedResolutions, setExpandedResolutions] = useState([]);
    const [globalVote, setGlobalVote] = useState(null);
    const [voteAmendement, setVoteAmendement] = useState(null);
    const [connaissanceDocument, setConnaissanceDocument] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const location = useLocation();
    const produitAGId = location.state?.produitAGId;

    const navigate = useNavigate();

     useEffect(() => {
        async function fetchProduitAG() {
            setIsLoading(true);
            const res = await getProduitAGById(produitAGId);
            const resResolutionsAG = await getResolutionsAGByProduitAGId(produitAGId);
            setProduitAG(res);
            setResolutionsAG(resResolutionsAG);
            setIsLoading(false);
        }

        fetchProduitAG();
    }, [produitAGId]);

    const toggleResolution = (resolutionId) => {
        setExpandedResolutions(prev => 
            prev.includes(resolutionId) ? prev.filter(id => id !== resolutionId) : [...prev, resolutionId]
        );
    };

    const handleVoteChange = (resolutionId, vote, e) => {
        e.stopPropagation();
    
        const newVotes = {...votes, [resolutionId]: vote};
        setVotes(newVotes);
        console.log(newVotes);
    
        const allVotesIdentical = resolutionsAG.every(resolution => newVotes[resolution.id] === vote);


        if (allVotesIdentical) {
            setGlobalVote(vote); 
        } else {
            setGlobalVote(null); 
        }
    };
    
    const handleConfirmSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await voteAG(votes, voteAmendement, connaissanceDocument);
            if (response.status === 200) {
                setSuccessMessage(response.message);
                setTimeout(() => {
                    navigate('/ag');
                    setShowModal(false);
                }, 3000);
 
            } else {
                setErrorMessage(response.message || "Une erreur s'est produite. Veuillez réessayer.");
            }
        } catch (err) {
            setErrorMessage(err.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        }
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
    };

    const handleVoteAmendementChange = (vote) => {
        setVoteAmendement(vote);
    };

    const handleConnaissanceDocumentChange = (e) => {
        setConnaissanceDocument(e.target.checked);
    };
    

    const handleGlobalVoteChange = (vote) => {
        const currentVote = globalVote;
        const newVote = currentVote === vote ? null : vote;
    
        setGlobalVote(newVote);
    
        if (newVote) {
            const updatedVotes = resolutionsAG.reduce((acc, resolution) => {
                acc[resolution.id] = newVote;
                return acc;
            }, {});
            setVotes(updatedVotes);
        } else {
            setVotes({}); // Effacer tous les votes si rien n'est sélectionné
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        handleOpenModal();
    };

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Container className="produit-ag-container">
                    <h1 className="text-center page-title">Vote sur le produit {produitAG.nom}</h1>
                    <Card className="info-card">
                        <Card.Body>
                            <Card.Title className="text-center">Vote par internet</Card.Title>
                            <Card.Text><strong>Vous avez choisi de voter par internet.</strong>
                                <br/>
                                <br/>
                                Vous vous exprimez sur les résolutions en cochant la case correspondant à votre choix. Si vous cochez la case "abstention", votre vote sera considéré comme un vote dévaforable.
                                Le texte des résolutions proposées est consultable en cliquant sur le signe +. Il figure également dans le rapport annuel consultable dans la documentation en ligne.
                                <br/>
                                <br/>
                                <strong>Les résolutions à voter sont les suivantes :</strong>
                            </Card.Text>
                            <Form onSubmit={handleSubmit}>
                                <Card className="shadow mb-3">
                                    <Card.Header>
                                    <div className="d-flex justify-content-between align-items-center">
                                                    <span>(Cliquez sur le + pour lire la résolution)</span>
                                        <div onClick={(e) => e.stopPropagation()}>
                                                <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    label="Pour"
                                                    name="globalVote"
                                                    checked={globalVote === 'Pour'}
                                                    onChange={() => handleGlobalVoteChange('Pour')}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    label="Contre"
                                                    name="globalVote"
                                                    checked={globalVote === 'Contre'}
                                                    onChange={() => handleGlobalVoteChange('Contre')}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    label="Abstention"
                                                    name="globalVote"
                                                    checked={globalVote === 'Abstention'}
                                                    onChange={() => handleGlobalVoteChange('Abstention')}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    label="Pouvoir au Président"
                                                    name="globalVote"
                                                    checked={globalVote === 'Pouvoir au Président'}
                                                    onChange={() => handleGlobalVoteChange('Pouvoir au Président')}
                                                />
                                        </div>
                                    </div>
                                    </Card.Header>
                                        {resolutionsAG.map(resolution => (
                                            <Card key={resolution.id}>
                                            <Card.Header className="header-resolution" onClick={() => toggleResolution(resolution.id)}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span> <strong>{expandedResolutions.includes(resolution.id) ? '-' : '+'} {resolution.nom}</strong></span>
                                                        <div onClick={handleCheckboxClick}>
                                                            <Form.Check
                                                                inline
                                                                type="radio"
                                                                label="Pour"
                                                                name={resolution.id}
                                                                checked={votes[resolution.id] === 'Pour'}
                                                                onChange={(e) => handleVoteChange(resolution.id, 'Pour', e)}
                                                                required
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type="radio"
                                                                label="Contre"
                                                                name={resolution.id}
                                                                checked={votes[resolution.id] === 'Contre'}
                                                                onChange={(e) => handleVoteChange(resolution.id, 'Contre', e)}
                                                                required
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type="radio"
                                                                label="Abstention"
                                                                name={resolution.id}
                                                                checked={votes[resolution.id] === 'Abstention'}
                                                                onChange={(e) => handleVoteChange(resolution.id, 'Abstention', e)}
                                                                required
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type="radio"
                                                                label="Pouvoir au Président"
                                                                name={resolution.id}
                                                                checked={votes[resolution.id] === 'Pouvoir au Président'}
                                                                onChange={(e) => handleVoteChange(resolution.id, 'Pouvoir au Président', e)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Header>
                                                {expandedResolutions.includes(resolution.id) && (
                                                    <Card.Body>
                                                        <Card.Text>{resolution.texte}</Card.Text>
                                                    </Card.Body>
                                                )}
                                            </Card>
                                        ))}
                                
                                </Card>
                                <Card className="shadow mb-3">
                                    <Card.Body>
                                        <Card.Text><strong>Si des amendements ou des résolutions nouvelles étaient présentés à l'Assemblée Générale :</strong>
                                            <br/>
                                            <br/>
                                            <Form.Check
                                                type="radio"
                                                name="voteFuturResolution"
                                                label="Je donne procuration au Président de l'Assemblée Générale de voter en mon nom"
                                                checked={voteAmendement === 'Procuration au Président'}
                                                onChange={() => handleVoteAmendementChange('Procuration au Président')}
                                                required
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="voteFuturResolution"
                                                label="Je m'abstiens (l'abestention est considérée comme un vote contre)"
                                                checked={voteAmendement === 'Abstention'}
                                                onChange={() => handleVoteAmendementChange('Abstention')}
                                                required
                                            />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <div className="d-flex justify-content-center mt-3">
                                    <Button variant="primary" type="submit" >Enregistrer les votes</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Form onSubmit={handleConfirmSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Vous avez choisi de voter par intenret</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage &&
                            (<p style={{ color: "green", marginBottom: "20px" }}>{successMessage}</p>
                            )}
                        {errorMessage && (
                            <p style={{ color: "red", marginBottom: "20px" }}>{errorMessage}</p>
                        )}
                        <p>Confirmez-vous votre choix ?</p>
                        <Form.Check 
                            type="checkbox" 
                            name="connaissanceDocument"
                            label="En cochant cette case, je reconnais avoir pris connaissance des documents relatifs à l'Assemblée Générale" 
                            checked={connaissanceDocument}
                            onChange={handleConnaissanceDocumentChange} 
                            required
                        />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center mt-3">
                        <Button variant="primary" type="submit">Enregistrer</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </PageContainer>

    );
}

export default ResolutionAG;
