import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { getContactsByRole } from "../services/Contact";
import { FaEnvelope, FaPhone, FaMobileAlt } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import PageContainer from "../components/PageContainer";

function Contact() {
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getContactsLoad() {
            const res = await getContactsByRole();
            setContacts(res);
            setIsLoading(false);
        }
        getContactsLoad();
    }, []);

    const groupContactsByService = (contacts) => {
        return contacts.reduce((grouped, contact) => {
            if (!grouped[contact.service]) {
                grouped[contact.service] = [];
            }
            grouped[contact.service].push(contact);
            return grouped;
        }, {});
    };

    let groupedContacts = {};
    if (contacts && contacts.length > 0) {
        groupedContacts = groupContactsByService(contacts);
    }

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                <Container className="my-5">
                <h1 className="text-center my-2" style={{ color: "#243E45" }}>
                    Les Contacts
                </h1>
                <Card className="shadow-lg my-3">
                    <Card.Body>
                        {Object.keys(groupedContacts).map((service, index) => (
                            <div key={index}>
                                <h4 className="mb-4" style={{color: '#243E45'}}>{service}</h4>
                                <Row>
                                    {groupedContacts[service].map((contact, index) => (
                                        <Col md={6} lg={4} className="mb-4" key={index}>
                                            <Card className="shadow h-100 bg-light">
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Image src={contact.pathImage} roundedCircle className="mx-auto d-block" style={{ height: "130px"}}/>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <p style={{color: '#243E45'} }>
                                                                <strong>
                                                                    {contact.firstName} {contact.lastName}
                                                                </strong>
                                                            </p>
                                                            {contact.email && (
                                                                <p>
                                                                    <FaEnvelope className="mr-2" style={{ marginRight: "4px" }}/> 
                                                                    <a href={`mailto:${contact.email}`} style={{ textDecoration: "none", color: "inherit" }}>
                                                                        {contact.email}
                                                                    </a>
                                                                </p>
                                                            )}
                                                            {contact.landline && (
                                                                <p>
                                                                    <FaPhone className="mr-2"/>
                                                                    <span> {contact.landline}</span>
                                                                </p>
                                                            )}
                                                            {contact.phoneNumber && (
                                                                <p>
                                                                    <FaMobileAlt className="mr-2" />
                                                                    <span> {contact.phoneNumber}</span>
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                                {index !== Object.keys(groupedContacts).length - 1 && (
                                    <hr />
                                )}
                            </div>
                        ))}
                    </Card.Body>
                </Card>
                </Container>
                </>
            )}
        </PageContainer>
    );
}

export default Contact;
