import React, {useState} from "react";
import { Card, Container, Form, Button, Col, Row, Alert } from "react-bootstrap";
import PageContainer from "../components/PageContainer";
import '../css/Simulations.css';
import { sendParrainage } from "../services/Parainage";

function Parrainage() {

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [mail, setMail] = useState("");
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [mailError, setMailError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");


    const handleClick = async (e) => {
        e.preventDefault();
        // Réinitialiser les messages d'erreur
        setLastNameError("");
        setFirstNameError("");
        setMailError("");
        setPhoneNumberError("");
        let isValid = true;

        // Validation des champs
        if (!lastName) {
            setLastNameError("Le champ Nom est obligatoire.");
            isValid = false;
        }
        if (!firstName) {
            setFirstNameError("Le champ Prénom est obligatoire.");
            isValid = false;
        }
        if (!mail) {
            setMailError("Le champ Email est obligatoire.");
            isValid = false;
        } 
        if (!phoneNumber) {
            setPhoneNumberError("Le champ Numéro de téléphone est obligatoire.");
            isValid = false;
        }

        if (!isValid) return;
        try {
        
            const parainage = await sendParrainage(lastName, firstName, mail, phoneNumber, message );
            setSent(true);
            setLastName("");
            setFirstName("");
            setMail("");
            setPhoneNumber("");
            setMessage("");
            setTimeout(() => setSent(false), 3000);

        } catch (error) {
            setError(true);
            setTimeout(() => setError(null), 3000);
        }
    }
        
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        if (lastNameError) setLastNameError("");
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
        if (firstNameError) setFirstNameError("");
    };

    const handleMailChange = (e) => {
        setMail(e.target.value);
        if (mailError) setMailError("");
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        if (phoneNumberError) setPhoneNumberError("");
    };


    return (
        <PageContainer> 
            <Container className="my-5">
                <h1 className="text-center my-2" style={{ color: "#243E45" }}>
                    Offre de parrainage
                </h1>
                <Card className="shadow-lg my-3">
                    <Card.Body>
                        <div className=" mt-2 p-4 rounded border border-secondary bg-light ">
                            <h3>Profitez d'une caisse de Champagne ou de chèques cadeaux offerts !</h3>
                            <br />
                            <p>
                                En parrainant un proche, recevez des <strong>chèques cadeaux</strong> ou une <strong>caisse de six bouteilles de Champagne</strong>.
                            </p>
                            <p><strong>Voici les détails de notre offre :</strong></p>
                            <ul>
                                <li>Dès <strong>10 000 €</strong>, recevez un chèque cadeau Kadéo de 50 € pour vous et un pour votre filleul.</li>
                                <li>Dès <strong>20 000 €</strong>, recevez chacun un chèque cadeau Kadéo de 100 € ou une caisse de six bouteilles de Champagne chacun.</li>
                            </ul>
                            <p><strong>Condtitions :</strong></p>
                            <ul>
                                <li>Offre valable pour la première souscription à une solution France Valley.</li>
                                <li>Sous réserve d’un investissement initial de <strong>10 000 € minimum.</strong></li>
                                <li>Un parrain peut parrainer plusieurs fois.</li>
                            </ul>
                        </div>
                        <div className=" mt-2 p-4 rounded border border-secondary bg-light ">
                            <h4 className="mb-3 " style={{color: '#243E45'}}>Comment ça marche ?</h4>
                            
                            <p><strong>Remplissez le formulaire</strong></p>
                                <p>Indiquez le nom, le prénom, le numéro de téléphone, ainsi que l’adresse mail de votre filleul dans le formulaire ci-dessous.</p>

                            <p><strong>Votre filleul investit</strong></p>
                                <p>Votre filleul recevra un mail l'informant de votre souhait de le parrainer.</p>

                            <p><strong>Recevez votre récompense !</strong></p>
                            <p>Si vous avez opté pour le Champagne, voici ce qui vous attend :  il s'agit d'un Champagne "Brut Tradition" élaboré par le vigneron <strong>Stéphane Herbert</strong>, à partir de vignes appartenant à France Valley, situées à Rilly-la-Montagne, un village classé <strong>Premier Cru</strong> dans la Montagne de Reims. Ce Champagne est issu d'un assemblage de Chardonnay, Pinot noir et Pinot meunier. Vieilli en fût de chêne, il dévoile en bouche des notes de beurre et de miel, accompagnées d'arômes floraux et de fruits à coque. Raffiné et élégant, il sublimera vos moments festifs et accompagnera vos repas avec distinction.</p>
                        </div>
                
                                <Card className="mt-5">
                                    <Card.Body>
                                    {sent && <Alert variant="success">Votre message a bien été envoyé.</Alert>}
                                    {error && <Alert variant="danger">Une erreur est survenue.</Alert>}
                                        <Form>
                                            <Row className="mb-3" >
                                                <Col>
                                                    <Form.Label>Nom</Form.Label>
                                                    <Form.Control type="text" value={lastName}  onChange={handleLastNameChange} isInvalid={!!lastNameError} required />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Prénom</Form.Label>
                                                    <Form.Control type="text"value={firstName} onChange={handleFirstNameChange} isInvalid={!!firstNameError} required />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3" >
                                                <Col>
                                                    <Form.Label>E-mail</Form.Label>
                                                    <Form.Control type="text" value={mail} onChange={handleMailChange} isInvalid={!!mailError}  required />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Téléphone</Form.Label>
                                                    <Form.Control type="text"value={phoneNumber} onChange={handlePhoneNumberChange} isInvalid={!!phoneNumberError} required />
                                                </Col>
                                            </Row>
                                            <Form.Group controlId="message" className="mb-3">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control  
                                                    as="textarea"
                                                    rows={5} 
                                                    value={message}
                                                    name="message"
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-2 text-center">
                                                <Button className="btn btn-custom" type="submit" onClick={handleClick} >
                                                    Envoyer
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                           
                    </Card.Body>
                </Card>
            </Container>
        </PageContainer>
    );
}

export default Parrainage;
