import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row, Spinner, } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import { getNbpartsByProduitAGId, getProduitAGById } from "../services/ProduitAG";
import {useNavigate} from "react-router-dom";
import { saveAs } from "file-saver";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import '../css/ProduitAG.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFile } from "../services/Reporting";
import { getDocumentsAGByProduitAGId } from "../services/DocumentAG";
import { voteAGPresident } from "../services/VoteAG";

function ProduitAG() {
    const [isLoading, setIsLoading] = useState(true);
    const [produitAG, setProduitAG] = useState([]);
    const [documentsAG, setDocumentsAG] = useState([]);
    const [showModalDocument, setShowModalDocument] = useState(false);
    const [showModalVotePresident, setShowModalVotePresident] = useState(false);
    const [isVoteInternetChecked, setIsVoteInternetChecked] = useState(false);
    const [isVotePresidentChecked, setIsVotePresidentChecked] = useState(false);
    const [connaissanceDocument, setConnaissanceDocument] = useState(false);
    const [nbParts, setNbParts] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();


    
    const location = useLocation();
    const produitAGId = location.state?.produitAGId;

    useEffect(() => {
        async function getProduitAG() {
            setIsLoading(true);
            const res = await getProduitAGById(produitAGId);
            const resDocumentsAG = await getDocumentsAGByProduitAGId(produitAGId);
            const resNbParts = await getNbpartsByProduitAGId(produitAGId);
            setProduitAG(res);
            setDocumentsAG(resDocumentsAG);
            setNbParts(resNbParts);
            setIsLoading(false);
        }

        getProduitAG();
    }, [produitAGId]);

    const handleSubmitVote = async (e) => {
        e.preventDefault();
        if (isVoteInternetChecked) {
            navigate(`/resolutionAG`, { state: { produitAGId } });
        }
        if (isVotePresidentChecked) {
            handleShowModalVotePresident();
        }
    };

    const handleSubmitVotePresident = async (e) => {
        e.preventDefault();
        try {
            const response = await voteAGPresident(produitAGId, connaissanceDocument);
            if (response.status === 200) {
                setSuccessMessage(response.message);
                setTimeout(() => {
                    navigate('/ag')
                    setShowModalVotePresident(false);
                }, 3000);
            } else {
                setErrorMessage(response.message || "Une erreur s'est produite. Veuillez réessayer.");
            }
        } catch (err) {
            setErrorMessage(err.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        }
    };



    const handleVoteInternetChange = () => {
        setIsVoteInternetChecked(true);
        setIsVotePresidentChecked(false);
    };

    const handleVotePresidentChange = () => {
        setIsVoteInternetChecked(false);
        setIsVotePresidentChecked(true);
    };

    const handleConnaissanceDocumentChange = (e) => {
        setConnaissanceDocument(e.target.checked);
    };

    const handleShowModalVotePresident = () => setShowModalVotePresident(true);

    const handleShowModalDocument = () => setShowModalDocument(true);


    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFile(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            throw error;
        }
    };

    const handleDownloadDocumentAG = (path) => {

        const urlArray = path.split("/");
        const numberOfSubfolders = urlArray.length - 2;
        const fileName = urlArray[urlArray.length - 1];
        const filePath = numberOfSubfolders === 1
            ? `${urlArray[1]}/${fileName}`
            : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

        handleDownload(filePath, fileName);
    };

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
            <>
                <Container className="produit-ag-container">
                    <h1 className="text-center page-title">Vote sur le produit {produitAG.nom}</h1>
                    <Card className="info-card">
                        <Card.Body>
                            <Row>
                                <Col md={6} className="info-column">
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <h5 className="text-center">Vos informations</h5>
                                            {produitAG.nom}
                                            <p>Nombre de parts AGO : {nbParts}</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6} className="info-column">
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <Card.Text className="text-center">{produitAG.fPhrase}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button className="mx-auto" variant="primary" onClick={handleShowModalDocument}>
                                        Documentation de l'Assemblée générale
                                    </Button>
                                </Col>
                            </Row>

                            <Card className="mt-3">
                                <Card.Title className="text-center mt-3">Vote par internet</Card.Title>
                                <Card.Body>
                                    <Card.Text className="text-center">{produitAG.sPhrase}</Card.Text>
                                    <Form onSubmit={handleSubmitVote}>
                                        <Form.Group controlId="voteInternet">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Je vote par internet" 
                                                checked={isVoteInternetChecked}
                                                onChange={handleVoteInternetChange} />
                                        </Form.Group>
                                        <Form.Group controlId="votePresident">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Je souhaite que le Président de l'Assemblée générale vote favorablement aux résolutions agréées par la société de gestion et défavorablement à toute autre résolution." 
                                                checked={isVotePresidentChecked}
                                                onChange={handleVotePresidentChange} />
                                        </Form.Group>
                                        <div className="d-flex justify-content-center">
                                            <Button 
                                                variant="primary" 
                                                type="submit" 
                                                disabled={!isVoteInternetChecked && !isVotePresidentChecked}>
                                                Suivant
                                            </Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>

                        </Card.Body>
                    </Card>

                    <Modal show={showModalVotePresident} onHide={() => { setShowModalVotePresident(false) }} size="lg">
                        <Form onSubmit={handleSubmitVotePresident}>
                            <Modal.Header closeButton>
                                <Modal.Title className="w-100 text-center">Vote par internet</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {successMessage &&
                                    (<p style={{ color: "green", marginBottom: "20px" }}>{successMessage}</p>
                                    )}
                                {errorMessage && (
                                    <p style={{ color: "red", marginBottom: "20px" }}>{errorMessage}</p>
                                )}
                                <Card.Text className="text-center">Vous avez choisi de donner pouvoir au Président de l'Assemblée Générale afin qu'il vote favorablement aux résolutions agréées par la Société de Gestion et défavorablement à toute autre résolution.</Card.Text>
                                    <Form.Check 
                                        type="checkbox" 
                                        label="En cochant cette case, je reconnais avoir pris connaissance des documents relatifs à l'Assemblée Générale." 
                                        checked={connaissanceDocument}
                                        onChange={handleConnaissanceDocumentChange}
                                        required />
                            </Modal.Body> 
                            <Modal.Footer className="d-flex justify-content-center mt-3">
                                <Button variant="primary" type="submit">Enregistrer</Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    <Modal show={showModalDocument} onHide={() => { setShowModalDocument(false) }} size="lg">
                                <Modal.Header closeButton >
                                    <Modal.Title className="w-100 text-center">Documentation de l'Assemblée générale </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>Titre du document</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {documentsAG.map((document) => (
                                                <tr key={document.id}>
                                                    <td>{document.nom}</td>
                                                    <td>
                                                    <Button variant="primary" onClick={() => handleDownloadDocumentAG(document.chemin)}>
                                                        <FontAwesomeIcon icon={faDownload} /> Télécharger
                                                    </Button>
                                                    </td>
                                                </tr>
                                            )) }
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                        </Modal>
                </Container>
            </>
            )}
        </PageContainer>
    );
}

export default ProduitAG;
