import React, { useEffect, useState } from "react";
import {Container, Button, Modal, Card} from "react-bootstrap";
import { getUserById } from "../services/user";
import {
    getSearchClient, getSearchClient2020, getSearchClient2021, getSearchClient2022, getSearchClient2023,
    getTaxes2019,
    getTaxes2019ByClientId,
    getTaxes2020, getTaxes2020ByClientId,
    getTaxes2021, getTaxes2021ByClientId,
    getTaxes2022, getTaxes2022ByClientId,
    getTaxes2023, getTaxes2023ByClientId
} from "../services/Taxes";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import styles from "../css/MesInformationsFiscales.module.css";
import YearCard from "../components/YearCard";
import {getClientByUserId} from "../services/Client";
import {downloadFile} from "../services/Reporting";
import { saveAs } from "file-saver";
import PageContainer from "../components/PageContainer";



function MesInformationsFiscales() {

    const [taxes, setTaxes] = useState({ 2019: [], 2020: [], 2021: [], 2022: [], 2023: [] });
    const [searchResults, setSearchResults] = useState({ 2019: [], 2020: [], 2021: [], 2022: [], 2023: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedTax, setSelectedTax] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [role, setRole] = useState("");
    const [hasSearched, setHasSearched] = useState({ 2019: false, 2020: false, 2021: false, 2022: false, 2023: false });

    useEffect(() => {
        const getTaxesLoad = async () => {
            const res = await getUserById();
            setRole(res.role);
            const loadFunctions = {
                conseiller: async () => ({
                    2019: await getTaxes2019(),
                    2020: await getTaxes2020(),
                    2021: await getTaxes2021(),
                    2022: await getTaxes2022(),
                    2023: await getTaxes2023(),
                }),
                client: async () => {
                    const client = await getClientByUserId();
                    return {
                        2019: await getTaxes2019ByClientId(client.id),
                        2020: await getTaxes2020ByClientId(client.id),
                        2021: await getTaxes2021ByClientId(client.id),
                        2022: await getTaxes2022ByClientId(client.id),
                        2023: await getTaxes2023ByClientId(client.id),
                    };
                },
            };
            const dataLoadFunction = loadFunctions[res.role];
            if (dataLoadFunction) {
                const taxesData = await dataLoadFunction();
                setTaxes(taxesData);
                setIsLoading(false);
            }
        };
        getTaxesLoad();
    }, [role]);

    const handleSearch = async (year, searchTerm) => {
        if (searchTerm.trim() === '') {
            // Réinitialiser à l'état initial pour cette année
            setSearchResults(prev => ({ ...prev, [year]: taxes[year] }));
        } else {
            // Processus de recherche
            const searchFunction = getSearchFunctionForYear(year);
            const searchResults = await searchFunction(searchTerm);
            setSearchResults(prev => ({ ...prev, [year]: searchResults }));
            setHasSearched(prev => ({ ...prev, [year]: true }));
        }
    };


    const getSearchFunctionForYear = (year) => {
        const searchFunctions = {
            2019: getSearchClient,
            2020: getSearchClient2020,
            2021: getSearchClient2021,
            2022: getSearchClient2022,
            2023: getSearchClient2023,
        };
        return searchFunctions[year];
    };

    const handleYearClick = (year) => {
        if (selectedYear === year) {
            setSelectedYear(null);
        } else {
            setSelectedYear(year);
        }
    };

    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFile(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            throw error;
        }
    };

    const handleDownloadTax = (path) => {

        const urlArray = path.split("/");
        const numberOfSubfolders = urlArray.length - 2;
        const fileName = urlArray[urlArray.length - 1];
        const filePath = numberOfSubfolders === 1
            ? `${urlArray[1]}/${fileName}`
            : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

        handleDownload(filePath, fileName);
    }

    const handleShowModal = (tax, year) => {
        setSelectedTax(tax);
        setSelectedYear(year);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedTax(null);
        setShowModal(false);
    };

    const renderClientData = (taxData, year) => {
        if (role === "conseiller") {
            return taxData.map((taxe) => (
                <tr key={taxe.id}>
                    <td>{taxe.firstName} {taxe.lastName}</td>
                    <td>
                        <Button className="btn-custom" onClick={() => handleShowModal(taxe, year)}>
                            <FontAwesomeIcon icon={faEye} /> Voir les détails
                        </Button>
                    </td>
                </tr>
            ));
        } else if (role === "client") {
            return taxData.map((tax, index) => (
                <tr key={index}>
                    <td>{tax.nameFond}</td>
                    <td>
                        <Button className="btn-custom" onClick={() => handleDownloadTax(tax.path)}>
                            <FontAwesomeIcon icon={faDownload} /> Télécharger
                        </Button>
                    </td>
                </tr>
            ));
        }
    };

    const renderYearCard = (year) => {
        // Utilisez hasSearched pour décider quelles données afficher
        const currentData = hasSearched[year] ? searchResults[year] : taxes[year];
        return (
            <YearCard
                key={year}
                year={year}
                selectedYear={selectedYear}
                handleYearClick={() => handleYearClick(year)}
                taxData={currentData}
                renderClientData={() => renderClientData(currentData, year)}
                role={role}
                handleSearch={(term) => handleSearch(year, term)}
            />
        );
    };

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                        <h1 className={styles.title}>
                            {role === "conseiller" ? "Informations Fiscales et AG" : "Mes Informations Fiscales et AG"}
                        </h1>
                        <div>
                            <strong>Liste de mes Bordereaux Fiscaux de Revenus et Assemblées Générales</strong>
                            <br/>
                            <div className={styles.cardContainer}>
                                {Object.keys(taxes).length > 0 ? (
                                    <>
                                        {Object.keys(taxes).map((year) => {
                                            const currentTaxData = searchResults[year] && searchResults[year].length > 0 ? searchResults[year] : taxes[year];
                                            return (
                                                <React.Fragment key={year}>
                                                    {currentTaxData.length > 0 && (
                                                        renderYearCard(year, currentTaxData)
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Card className="shadow my-4 text-center">
                                        <Card.Body>
                                            Vos informations fiscales et AG seront bientôt disponibles.
                                        </Card.Body>
                                    </Card>
                                )}
                            </div>
                        </div>
                        <Modal show={showModal} onHide={handleCloseModal} size="lg">
                            <Modal.Header closeButton >
                                <Modal.Title className="w-100 text-center">{selectedTax?.firstName} {selectedTax?.lastName}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Produit</th>
                                            <th>Bordereaux Fiscaux</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {selectedTax && selectedYear && Array.isArray(selectedTax[`tax${selectedYear}s`]) && selectedTax[`tax${selectedYear}s`].map((tax, index) => (
                                            <tr key={index}>
                                                <td>{tax.nameFond}</td>
                                                <td>
                                                    <Button className="btn-custom" onClick={() => handleDownloadTax(tax.path)}>
                                                        <FontAwesomeIcon icon={faDownload} /> Télécharger
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                </>
                )}
        </PageContainer>
    );
}

export default MesInformationsFiscales;