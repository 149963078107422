import React, { useEffect, useState } from "react";
import {Container, Row, Col, Card} from "react-bootstrap";
import { Button, Modal, Form } from 'react-bootstrap';
import { FaUser, FaPhone, FaEnvelope, FaMapMarkerAlt, FaBirthdayCake, FaFlag, FaBuilding } from "react-icons/fa";
import { getUserById } from "../services/user";
import { getClientByUserId } from "../services/Client";
import { FaHandshake, FaUsers, FaBriefcase, FaGavel } from "react-icons/fa";
import PageContainer from "../components/PageContainer";

import {Link} from "react-router-dom";
import { getPartnerByUserId} from "../services/Partner";
import Spinner from "react-bootstrap/Spinner";
import {changePassword} from "../services/Auth";
import formatDate from "../components/FormatDate";
import '../css/Compte.css';

function Compte() {
    const [user, setUser] = useState({});
    const [client, setClient] = useState({});
    const [role, setRole] = useState("");
    const [partner, setPartner] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordCheck, setNewPasswordCheck] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        async function loadData() {
            const res = await getUserById();
            setUser(res);
            setRole(res.role);
            if (role === "client") {
                const resClient = await getClientByUserId(user.id);
                setClient(resClient);
            } else if (role === "conseiller") {
                const resPartner = await getPartnerByUserId(user.id);
                setPartner(resPartner);
            }
            setIsLoading(false);
        }
        loadData();
    }, [role]);

    const handleShowModal = () => setShowModal(true);


    const handleSubmit = async (e) => {
        e.preventDefault();
        clearErrorMessage();
        clearSuccessMessage();

        if (currentPassword === '') {
            setErrorMessage('Veuillez saisir votre mot de passe actuel.');
            return;
        }

        if (newPassword === '') {
            setErrorMessage('Veuillez saisir un nouveau mot de passe.');
            return;
        }

        if (newPassword.length < 8) {
            setErrorMessage('Le nouveau mot de passe doit contenir au moins 8 caractères.');
            return;
        }

        if (!/\d/.test(newPassword)) {
            setErrorMessage('Le nouveau mot de passe doit contenir au moins un chiffre.');
            return;
        }

        if (!/[A-Z]/.test(newPassword)) {
            setErrorMessage('Le nouveau mot de passe doit contenir au moins une lettre majuscule.');
            return;
        }

        if (newPassword !== newPasswordCheck) {
            setErrorMessage('Les nouveaux mots de passe ne correspondent pas.');
            return;
        }

        try {
            const response = await changePassword(currentPassword, newPassword, newPasswordCheck);
            if (response.status === 200) {
                setSuccessMessage(response.message);
                setTimeout(() => {
                    setShowModal(false);
                    resetForm();
                }, 3000);
            } else {
                setErrorMessage(response.message || "Une erreur s'est produite. Veuillez réessayer.");
            }
        } catch (err) {
            setErrorMessage(err.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        }
    };

    const resetForm = () => {
        setCurrentPassword('');
        setNewPassword('');
        setNewPasswordCheck('');
        setErrorMessage('');
    };

    const clearErrorMessage = () => {
        setErrorMessage("");
    };

    const clearSuccessMessage = () => {
        setSuccessMessage("");
    }


    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Nettoie le numéro en supprimant tous les caractères non numériques
      
        // Vérifie si le numéro a exactement 10 chiffres
        if (cleaned.length === 10) {
          // Formate le numéro en groupes de 2 chiffres séparés par des espaces
          return cleaned.replace(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1 $2 $3 $4 $5');
        }
      
        // Si ce n'est pas un numéro français standard, renvoie le numéro tel quel
        return phoneNumber;
      }


      function formatIban(iban) {
        if (!iban) {
            return 'IBAN non disponible';
        }
    
        // Remplacer les caractères par des 'X', sauf les 7 derniers
        const maskedPart = iban.substring(0, iban.length - 7).replace(/./g, 'X');
        const visiblePart = iban.substring(iban.length - 7);
    
        // Combiner les deux parties
        const combinedIban = maskedPart + visiblePart;
    
        // Ajouter des espaces tous les 4 caractères
        return combinedIban.match(/.{1,4}/g).join(' ');
    }


    return (
        <>
            <PageContainer isLoading={isLoading}>
                {isLoading ? (
                    <div className="d-flex justify-content-center mt-5">
                        <Spinner animation="border" role="status">
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <Container className="my-5">
                            <h1 className="text-center my-2" style={{color: '#243E45'}}>Mon Compte</h1>
                            <Card className="shadow-lg my-3">
                                <Card.Body >
                                    <div className="text-center mt-2 p-4 rounded border  border-secondary bg-light">
                                        <h4 className="mb-3 " style={{color: '#243E45'}}>Modification des informations personnelles</h4>
                                        <p>
                                            Pour des raisons de sécurité et de traçabilité, il n'est pas possible de modifier en ligne les informations personnelles vous concernant.
                                        </p>
                                        <p>
                                            Vous pouvez néanmoins faire une demande de modification d'informations via le formulaire ci-dessous, où vous nous indiquerez les informations à modifier :
                                        </p>
                                        <p>
                                            Pour un changement d'adresse postale, nous vous remercions de nous joindre un justificatif de domicile de moins de 6 mois à votre nouvelle adresse :
                                        </p>
                                        <p className="font-italic">
                                            Nature des justificatifs de domicile valides : facture de fourniture d’accès internet, facture de téléphone fixe ou mobile, attestation d’assurance habitation, facture ou échéancier de l’année en cours d’électricité ou de gaz ou d’eau (avec une consommation), dernier avis de taxe foncière, dernier avis de taxe d’habitation, dernier avis d’imposition d'IR (Impôt sur le Revenu) ou d'IFI (Impôt de Solidarité sur la Fortune)
                                        </p>
                                        {role === "conseiller" && (
                                            <a href={`mailto:backoffice@france-valley.com?subject=Extranet FRANCE VALLEY - Demande de modification - Compte Partenaire&body=${encodeURIComponent('Bonjour,\n\nNous vous remercions de prendre en compte la modification suivante sur notre compte Partenaire :')}`}>
                                                <Button className="btn-custom mt-3" >
                                                    Cliquez ici pour faire la demande de modification
                                                </Button>
                                            </a>

                                        )}
                                        {role === "client" && (
                                            <Link to={"/demandeModification"}>
                                                <Button className="btn-custom mt-3">
                                                    Cliquez ici pour faire la demande de modification
                                                </Button>
                                            </Link>
                                        )}

                                    </div>
                                    < br/>
                                    <Row>
                                        {role === "conseiller" && (
                                            <Col md={4}>
                                                <Card className="shadow bg-light">
                                                    <Card.Body>
                                                        <Card.Title className="mb-4 text-center" style={{color: '#243E45'}}>Mes informations</Card.Title>
                                                        <Card.Text className="grey-out">
                                                            <FaBuilding className="mr-2"/>  <strong> Société :</strong> {partner.name}
                                                        </Card.Text>
                                                        <Card.Text className="grey-out">
                                                            <FaEnvelope className="mr-2"/> <strong> Email :</strong> {user.email}
                                                        </Card.Text>
                                                        <Card.Text className="grey-out">
                                                            <FaPhone className="mr-2"/>  <strong> Numéro de téléphone :</strong> {formatPhoneNumber(partner.phoneNumber)}
                                                        </Card.Text>
                                                        {partner.address1 && (
                                                            <Card.Text className="grey-out">
                                                                <FaMapMarkerAlt className="mr-2"/>  <strong> Adresse :</strong> {partner.address1}
                                                            </Card.Text>
                                                        )}
                                                        {partner.address2 && (
                                                            <Card.Text className="grey-out">
                                                                <FaMapMarkerAlt className="mr-2" />  <strong> Complément d'adresse 1 :</strong> {partner.address2}
                                                            </Card.Text>
                                                        )}
                                                        <Card.Text className="grey-out">
                                                            <FaMapMarkerAlt className="mr-2"/>  <strong> Ville :</strong> {partner.city}
                                                        </Card.Text>
                                                        <Card.Text className="grey-out">
                                                            <FaMapMarkerAlt className="mr-2" />  <strong> Code postal :</strong> {partner.zipCode}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )}
                                        {role === "client" && (
                                            <Col md={4}>
                                                <Card className="shadow bg-light">
                                                    <Card.Body>
                                                        <Card.Title className="mb-4 text-center" style={{color: '#243E45'}}>Mes informations</Card.Title>
                                                        <Card.Text className="grey-out">
                                                            <FaUser className="mr-2"/>  <strong> Civilité :</strong> {client.civility}
                                                        </Card.Text>
                                                        <Card.Text className="grey-out">
                                                            <FaUser className="mr-2" />  <strong> Nom :</strong> {client.lastName}
                                                        </Card.Text>
                                                        <Card.Text className="grey-out">
                                                            <FaUser className="mr-2"  /> <strong> Prénom :</strong> {client.firstName}
                                                        </Card.Text>
                                                        {client.birthDate && (
                                                            <Card.Text className="grey-out">
                                                                <FaBirthdayCake className="mr-2" /> <strong> Date de naissance :</strong> {formatDate(client.birthDate)}
                                                            </Card.Text>
                                                        )}
                                                        <Card.Text className="grey-out">
                                                            <FaPhone className="mr-2" /> <strong> Numéro de téléphone :</strong> {formatPhoneNumber(client.phoneNumber)}
                                                        </Card.Text>
                                                        {user.phoneNumber2 && (
                                                            <Card.Text className="grey-out">
                                                                <FaPhone className="mr-2" /> <strong> Numéro de téléphone 2 :</strong> {formatPhoneNumber(client.phoneNumber2)}
                                                            </Card.Text>
                                                        )}
                                                        <Card.Text className="grey-out">
                                                            <FaEnvelope className="mr-2" /> <strong> Email :</strong> {user.email}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )}
                                        {role === "client" && (
                                            <>
                                                <Col md={4}>
                                                    <Card className="shadow bg-light">
                                                        <Card.Body>
                                                            <Card.Title className="mb-4 text-center" style={{color: '#243E45'}}>Mon adresse</Card.Title>
                                                            <Card.Text className="grey-out">
                                                                <FaMapMarkerAlt className="mr-2" /> <strong> Adresse :</strong> {client.address}
                                                            </Card.Text>
                                                            {client.complementAddress1 && (
                                                                <Card.Text className="grey-out">
                                                                    <FaMapMarkerAlt className="mr-2" /> <strong> Complément d'adresse 1 :</strong> {client.complementAddress1}
                                                                </Card.Text>
                                                            )}
                                                            {client.complementAddress2 && (
                                                                <Card.Text className="grey-out">
                                                                    <FaMapMarkerAlt className="mr-2" /> <strong> Complément d'adresse 2 :</strong> {client.complementAddress2}
                                                                </Card.Text>
                                                            )}
                                                            <Card.Text className="grey-out">
                                                                <FaMapMarkerAlt className="mr-2" /> <strong> Ville :</strong> {client.city}
                                                            </Card.Text>
                                                            <Card.Text className="grey-out">
                                                                <FaMapMarkerAlt className="mr-2" /> <strong> Code postal :</strong> {client.zipCode}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={4}>
                                                    <Card className="shadow bg-light mb-3">
                                                        <Card.Body>
                                                            <Card.Title className="mb-4 text-center" style={{ color: "#243E45" }}>Paramètre du compte</Card.Title>
                                                            <Button className="btn-custom mx-auto d-block"  onClick={handleShowModal}>
                                                                Modifier le mot de passe
                                                            </Button>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </>
                                        )}

                                        {role === "conseiller" && (
                                            <>
                                                <Col md={4}>
                                                    <Card className="shadow bg-light">
                                                        <Card.Body>
                                                            <Card.Title className="mb-4 text-center" style={{color: '#243E45'}}>Informations Juridiques</Card.Title>
                                                            <Card.Text className="grey-out">
                                                                <FaGavel className="mr-2" /> <strong> Siren :</strong>  {partner.siren}
                                                            </Card.Text>
                                                            <Card.Text className="grey-out">
                                                                <FaBriefcase className="mr-2"/> <strong> Vat :</strong> {partner.vat}
                                                            </Card.Text>
                                                            <Card.Text className="grey-out">
                                                                <FaUsers className="mr-2"/> <strong> Association :</strong> {partner.association}
                                                            </Card.Text>
                                                            <Card.Text className="grey-out">
                                                                <FaHandshake className="mr-2" /> <strong> Iban :</strong> {formatIban(partner.iban)}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={4}>
                                                    <Card className="shadow mb-3 bg-light">
                                                        <Card.Body>
                                                            <Card.Title className="mb-4 text-center" style={{ color: "#243E45" }}>Paramètre du compte</Card.Title>
                                                            <Button className="btn-custom mx-auto d-block"  onClick={handleShowModal}>
                                                                Modifier le mot de passe
                                                            </Button>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Container>
                    </>
                )}
            </PageContainer>
            <Modal show={showModal} onHide={() => { setShowModal(false); resetForm(); setSuccessMessage(''); }}>
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Changer le mot de passe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {successMessage &&
                        (<p style={{ color: "green", marginBottom: "20px" }}>{successMessage}</p>
                        )}
                    {errorMessage && (
                        <p style={{ color: "red", marginBottom: "20px" }}>{errorMessage}</p>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formCurrentPassword">
                            <Form.Label>Mot de passe actuel</Form.Label>
                            <Form.Control type="password" placeholder="Mot de passe actuel" value={currentPassword} onChange={(e) => {setCurrentPassword(e.target.value);  clearErrorMessage();}} />
                        </Form.Group>
                        <Form.Group controlId="formNewPassword">
                            <Form.Label>Nouveau mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Nouveau mot de passe" value={newPassword} onChange={(e) => { setNewPassword(e.target.value);  clearErrorMessage();}}/>
                        </Form.Group>
                        <Form.Group controlId="formConfirmNewPassword">
                            <Form.Label>Confirmer le nouveau mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Confirmer le nouveau mot de passe" value={newPasswordCheck} onChange={(e) => { setNewPasswordCheck(e.target.value);  clearErrorMessage();}}/>
                        </Form.Group>
                        <Button  type="submit" className="btn-custom mx-auto d-block mt-3">
                            Modifier le mot de passe
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Compte;
