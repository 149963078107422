import React, { useEffect, useState } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes, useNavigate, Navigate} from "react-router-dom";
import Login from "./pages/Login";
import Actualite from "./pages/Actualite";
import Compte from "./pages/Compte";
import DemandeModification from "./pages/DemandeModification";
import Reportings from "./pages/Reportings";
import MesSouscriptions from "./pages/MesSouscriptions";
import REsouscription from "./pages/REsouscription";
import MesInformationsFiscales from "./pages/MesInformationsFiscales";
import MesCollaborateurs from "./pages/MesCollaborateurs";
import Commissions from "./pages/Commissions";
import Fonds from "./pages/Fonds";
import Simulations from "./pages/Simulations";
import Contact from "./pages/Contact";
import ModifyPDF from "./pages/testPdf";
import SouscriptionEnLigne from "./pages/SouscriptionEnLigne";
import ForgotPasswordConseiller from "./pages/ForgotPasswordConseiller";
import ForgotPasswordClient from "./pages/ForgotPasswordClient";
import ChangePasswordClient from "./pages/changePasswordClient";
import ChangePasswordConseiller from "./pages/changePasswordConseiller";
import ModifSouscription from "./pages/ModifSouscription";
import Dashboard from "./pages/Dashboard";
import LoginClient from "./pages/LoginClient";
import LoginPartner from "./pages/LoginPartner";
import FraisExPost from "./pages/FraisExPosts";
import AG from './pages/AG';
import ProduitAG from './pages/ProrduitAG';
import ResolutionAG from './pages/ResolutionAG';
import VoteAG from './pages/VoteAG';
import { checkSubOnlineUser} from "./services/user";
import Page404 from './pages/Page404';
import ChangeOldPasswordClient from './pages/changeOldPasswordClient';
import ChangeOldPasswordPartner from './pages/changeOldPasswordPartner';
import Parrainage from './pages/Parrainage';
import DocCenter from './pages/Doccenter';
import Reporting from './pages/Reporting';
import Faq from './pages/Faq';

function App() {
  
  const [currentUser, setCurrentUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await checkSubOnlineUser();
        setCurrentUser(user);

        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchUser();
  }, []);
  


  function LoggedInElement({children}) {
    if (isLoading) {
      return <></>;
    }
    if (currentUser.checkLogin === true) {
      return <>{children}</>;
    } else {
      return <Login/>;
    }
  }

  function ConseillerElement({children}) {
    if (isLoading) {
      return <></>;
    }
    if (currentUser.role === "conseiller") {
      return <>{children}</>;
    } else {
      return <Page404/>;
    }
  }

  function ClientElement({children}) {
    if (isLoading) {
      return <></>;
    }
    if (currentUser.role === "client") {
      return <>{children}</>;
    } else {
      return <Page404/>;
    }
  }

  function ResouscriptionElement({children}) {
    if (isLoading) {
      return <></>;
    }
    if (currentUser.checkResouscription === true) {
      return <>{children}</>;
    } else {
      return <Page404/>;
    }
  }

/*   function ParrainageElement({children}) {
    if (isLoading) {
      return <></>;
    }
    if (parrainage === true) {
      return <>{children}</>;
    } else {
      return <Page404/>;
    }
  } */

  return (
      <div className='body-custom-font'>
        
        <Router>
            <Routes>
              <Route path="/actualites" element={<LoggedInElement><Actualite /></LoggedInElement>} />
              <Route path="/compte" element={<LoggedInElement><Compte /></LoggedInElement>} />
              <Route path="/demandeModification" element={<LoggedInElement><ClientElement><DemandeModification /></ClientElement></LoggedInElement>} />
              <Route path="/reportings" element={<LoggedInElement><Reportings /></LoggedInElement>} />
              <Route path="/mesSouscriptions" element={<LoggedInElement><MesSouscriptions /></LoggedInElement>} />
              <Route path="/resouscription" element={<LoggedInElement><ResouscriptionElement><REsouscription /></ResouscriptionElement></LoggedInElement>} />
              <Route path="/mesInformationsFiscales" element={<LoggedInElement><MesInformationsFiscales /></LoggedInElement>} />
              <Route path="/mesCollaborateurs" element={<LoggedInElement><ConseillerElement><MesCollaborateurs /></ConseillerElement></LoggedInElement>} />
              <Route path="/commissions" element={<LoggedInElement><ConseillerElement><Commissions /></ConseillerElement></LoggedInElement>} />
            
              <Route path="/simulations" element={<LoggedInElement><ConseillerElement><Simulations /></ConseillerElement></LoggedInElement>} />
              <Route path="/contact" element={<LoggedInElement><Contact /></LoggedInElement>} />
              <Route path="/modifyPDF" element={<ModifyPDF />} />
              <Route path="/souscriptionEnLigne" element={<LoggedInElement><SouscriptionEnLigne/></LoggedInElement>} />
              <Route path="/modifSouscription" element={<LoggedInElement><ModifSouscription /></LoggedInElement>} />
              <Route path="/fraisExPost" element={<LoggedInElement><ConseillerElement><FraisExPost /></ConseillerElement></LoggedInElement>} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path='/parrainage' element={<LoggedInElement><ClientElement><Parrainage></Parrainage></ClientElement></LoggedInElement>} />
              <Route path='/FAQ' element={<LoggedInElement><ClientElement><Faq /></ClientElement></LoggedInElement>} />
               <Route path="/ag" element={<AG />} />
              <Route path="/produitAG" element={<ProduitAG />} />
              <Route path="/resolutionAG" element={<ResolutionAG />} />
              <Route path="/voteAG" element={<VoteAG />} /> 
              <Route path="/" element={<Login />} />
              <Route path="/conseiller/motDePasseOublie" element={<ForgotPasswordConseiller />} />
              <Route path="/ChangementMotDePasseClient" element={<ChangeOldPasswordClient />} />
              <Route path="/ChangementMotDePasseConseiller" element={<ChangeOldPasswordPartner />} />
              <Route path="/conseiller/motDePasseOublie" element={<ForgotPasswordConseiller />} />
              <Route path="/client/motDePasseOublie" element={<ForgotPasswordClient />} />
              <Route path="/modifMotDePasseClient" element={<ChangePasswordClient />} />
              <Route path="/modifMotDePasseConseiller" element={<ChangePasswordConseiller />} />
              <Route path="/loginClient" element={<LoginClient />} />
              <Route path='/doccenter' element={<DocCenter /> } />
              <Route path="/doccenter/doc" element={<Navigate to="/doccenter" replace />} />
              <Route path="/extranet" element={<Navigate to="/" replace />} />
              <Route path='/reporting' element={<Reporting /> } />
              <Route path="/fonds" element={<LoggedInElement><ConseillerElement><Fonds /></ConseillerElement></LoggedInElement>} />
              <Route path="/loginPartner" element={<LoginPartner />} />
              <Route path="*" element={<Page404/>} />
            </Routes>
        </Router>
      </div>
  );
}

export default App;

