import axios from 'axios';

export const voteAG = async (votes, voteAmendement, connaissanceDocument) => {
    try {
        const response = await axios.post("https://dev.france-valley.paris/api/votesAG/voteByClient", {votes, voteAmendement, connaissanceDocument} , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const voteAGPresident = async (produitAGId, connaissanceDocument) => {
    try {
        const response = await axios.post("https://dev.france-valley.paris/api/votesAG/pouvoirPresident", {produitAGId, connaissanceDocument} , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getVoteAGByProduitAGId = async (produitAGId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/votesAG/byClient/${produitAGId}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
 