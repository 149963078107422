import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import CardArticle from "../components/CardArticle";
import {getArticles} from "../services/Article";
import Spinner from "react-bootstrap/Spinner";
import PageContainer from "../components/PageContainer";

function Actualite() {
 const [articles, setArticles] = useState([]);
 const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getArticlesLoad() {
            const res = await getArticles();
            setArticles(res);
            setIsLoading(false);
        }
        getArticlesLoad();
    }, []);

  return (
      <PageContainer isLoading={isLoading}>
          {isLoading ? (
              <div className="d-flex justify-content-center mt-5">
                  <Spinner animation="border" role="status">
                  </Spinner>
              </div>
          ) : (
            <>
              <Container className="my-5">
                  <h1 className="text-center" style={{color: '#243E45'}}>Les Actualités</h1>
                  <Row xs={1} md={3} className="g-4 my-3">
                      {articles.map((article) => (
                          <Col key={article.id} xs={12} md={4}>
                              <CardArticle article={article} />
                          </Col>
                      ))}
                  </Row>
              </Container>
              </>
            )}
      </PageContainer>
  );
}

export default Actualite;
