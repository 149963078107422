import axios from 'axios';

export const getProduitsAGByClient = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/produitsAG/byClient', { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getProduitAGById = async (produitAGId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/produitsAG/${produitAGId}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getNbpartsByProduitAGId = async (produitAGId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/produitsAG/shareNumber/${produitAGId}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}