import axios from "axios";

export const sendParrainage = async (lastName, firstName, email, phoneNumber, message) => {
    const formData = {
        lastName,
        firstName,
        email,
        phoneNumber,
        message
    };

    try {
        const response = await axios.post("https://dev.france-valley.paris/api/parrainages/sendEmail", 
        formData , 
        { 
            withCredentials: true,
        } 
    );
        return response;
    } catch (error) {
        throw error;
    }
};