import React, { useEffect, useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { getCommissionByPartnerId } from "../services/Commission";
import { getPartnerByUserId } from "../services/Partner";
import { downloadFile } from "../services/Reporting";
import { saveAs } from "file-saver";
import { BsDownload } from "react-icons/bs";
import Spinner from "react-bootstrap/Spinner";
import formatDate from "../components/FormatDate";
import PageContainer from "../components/PageContainer";
import "../css/Commissions.css";



function Commissions() {
    const [commission, setCommission] = useState([]);
    const [partnerId, setPartnerId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCommission, setSelectedCommission] = useState("");

    useEffect(() => {
        async function getCommissionLoad() {
            const resPartner = await getPartnerByUserId();
            setPartnerId(resPartner.id);
            if (partnerId !== null) {
                const res = await getCommissionByPartnerId(partnerId);
                setCommission(res);
            }
            setIsLoading(false);
        }
        getCommissionLoad();
    }, [partnerId]);


    const handleCommissionChange = (event) => {
        setSelectedCommission(event.target.value);
    };

    const handleDownloadCommission = () => {
        const selectedCommissionObj = commission.find(
            (item) => item.path === selectedCommission
        );

        if (selectedCommissionObj && selectedCommissionObj.path) {
            const urlArray = selectedCommissionObj.path.split("/");
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            handleDownload(filePath, fileName);
        } else {
            throw new Error("Impossible de télécharger la commission");
        }
    };

    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFile(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            throw error;
        }
    };

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                        <h1 className="text-center my-2" style={{ color: "#243E45" }}>Liste de mes Commissions</h1>
                        <Card className="shadow-lg my-3">
                            <Card.Body>
                                    <div className="mt-2 p-4 rounded border border-success bg-light  ">
                                        <h4 className="mb-3" style={{ color: "#243E45" }}>Les relevés Partenaires comprennent deux parties :</h4>
                                        <p>
                                            Première partie : rétrocession de commission de souscription (auto-facturation établie par FRANCE VALLEY en votre nom et pour votre compte ; vous n'avez donc pas à transmettre de facture)
                                        </p>
                                        <p> Seconde partie : relevé des droits d'entrée des Groupements (si vous avez appliqué des droits d'entrée)
                                        </p>
                                        <p>
                                            Note: les droits d'entrée sont versés au Partenaire par chaque Groupement. Ainsi, vous ne recevez pas un règlement global pour l'ensemble des droits d'entrée, mais un ensemble de virements de chaque Groupement.
                                        </p>
                                    </div>
                                <Form.Group className="my-3">
                                    <Form.Label>Choisir un relevé :</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="form-select shadow-sm"
                                        value={selectedCommission}
                                        onChange={handleCommissionChange}
                                    >
                                        <option value="">Sélectionnez un relevé</option>
                                        {commission.map((item, index) => (
                                            <option key={index} value={item.path}>
                                                {formatDate(item.date)}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <div className="my-3">
                                    <Button
                                       className="btn-custom" 
                                        onClick={handleDownloadCommission}
                                        disabled={!selectedCommission}
                                    >
                                        <BsDownload className="me-2" />
                                        Télécharger le relevé
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Container>
                </>
            )}
        </PageContainer>
    );
}

export default Commissions;

