import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Container,
    Form,
    ListGroup,
    Row,
    Col,
} from "react-bootstrap";
import { getAllFondsActive, getSyntheseFonds} from "../services/Fond";
import { BsDownload } from "react-icons/bs";
import {getFondDocumentsByFondId} from "../services/FondDocument";
import {downloadFile} from "../services/Reporting";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import '../css/Fonds.css';
import {Link} from "react-router-dom";
import {checkLastSubscribeForClient} from "../services/Subscribe";
import Spinner from "react-bootstrap/Spinner";
import {getUserById} from "../services/user";
import PageContainer from "../components/PageContainer";

function Fonds() {
    const [selectedFond, setSelectedFond] = useState("");
    const [fonds, setFonds] = useState([]);
    const [fondDocuments, setFondDocuments] = useState([]);
    const [fondId, setFondId] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPartner, setIsPartner] = useState(null);
    const documentsRef = useRef(null);
    const [role, setRole] = useState(null);
    const [syntheseFonds, setSyntheseFonds] = useState({});

    useEffect(() => {
        async function getFondsLoad() {

            const resUser = await getUserById();
            setRole(resUser.role);
            const res = await getAllFondsActive();
            setFonds(res);

            if (resUser.role === "conseiller") {
                const resSynthese = await getSyntheseFonds();
                setSyntheseFonds(resSynthese);
                //je le passe a true car il est conseiller
                setIsPartner(true);
            }

             if (resUser.role === "client") {
                //check si la derniere souscription est de France Valley
                const res = await checkLastSubscribeForClient();
                setIsPartner(res.isPartner);
            }
            setIsLoading(false)
        }
        getFondsLoad();
    }, []);

    const handleFondChange = async (event) => {
        const selectedId = Number(event.target.value);
        setSelectedFond(selectedId);
    
        const currentFond = fonds.find((fond) => fond.id === selectedId);
        if (currentFond) {
            setFondId(currentFond.id);
            try {
                const fondDocs = await getFondDocumentsByFondId(currentFond.id);
                setFondDocuments(fondDocs);

                // faire un scroll vers les documents
            setTimeout(() => {
                if (documentsRef.current) {
                    documentsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);
            } catch (error) {
                console.error("Erreur lors de la récupération des documents :", error);
            }
        } else {
            setFondId(null);
            setFondDocuments([]);
        }
    };
    


    const downloadSingleFile = async (fileRelativePath) => {
        try {
            const urlArray = fileRelativePath.split('/');
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            const response = await downloadFile(filePath);
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            saveAs(blob, fileName);
        } catch (error) {
            throw error;
        }
    };


    const downloadAllFiles = async (fondId) => {
        try {
            const fond = fonds.find((fond) => fond.id === fondId);
            if (!fond) {
                console.error("Fond introuvable");
                return;
            }

            const zip = new JSZip();

            const files = await Promise.all(
                fondDocuments.map(async (document) => {
                    const urlArray = document.path.split("/");
                    const numberOfSubfolders = urlArray.length - 2;
                    const fileName = urlArray[urlArray.length - 1];
                    const filePath = numberOfSubfolders === 1
                        ? `${urlArray[1]}/${fileName}`
                        : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

                    const response = await downloadFile(filePath);
                    const file = new Blob([response.data], { type: "application/pdf" });
                    return { name: fileName, file: file };
                })
            );

            files.forEach(({ name, file }) => {
                zip.file(name, file);
            });

            const content = await zip.generateAsync({ type: "blob" });
            saveAs(content, `${fond.nameFond}-documents.zip`);
        } catch (error) {
            throw error;
        }
    };

    const downlaodSyntheseFonds = async () => {
        try {
            const urlArray = syntheseFonds[0].path.split('/');
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            const response = await downloadFile(filePath);
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            saveAs(blob, 'Synthèse.pdf');
        } catch (error) {
            throw error;
        }
    };

    const fondSelected = fonds.find((fond) => fond.id === selectedFond);

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                        <h1 className="my-2 text-center" style={{ color: "#243E45" }}>
                            {role === "conseiller" ? "Les Fonds" : "Mes Fonds"}
                        </h1>
                        {!isPartner ? (
                            <Row>
                                <Col>
                                    <Card className="shadow my-4">
                                        <Card.Body>
                                            <h5 className="text-center mb-3" style={{ color: "#243E45" }}>
                                                Information importante
                                            </h5>
                                            <p className="text-center">
                                                Vous avez été conseillé par un CGP (Conseiller en Gestion de Patrimoine), une Banque Privée ou un Family Office.
                                            </p>
                                            <p className="text-center">
                                                N'hésitez pas à le contacter pour qu'il vous présente l'offre actuelle de Fonds Forestiers et Viticoles de France Valley et pour qu'il vous transmette un lien Internet permettant de souscrire 100% en ligne.
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                        <Card className="shadow-lg my-3 p-4 rounded">
                            {role === "conseiller" && (
                                <>
                                    <div className=" mt-2 p-4 rounded border border-primary bg-light text-center">
                                    <img
                                        src={process.env.PUBLIC_URL + "/fonds.jpg"}
                                        alt="fonds"
                                        className="img-fluid"
                                    />
                                         <Button
                                         onClick={() => downlaodSyntheseFonds()}
                                         className="btn btn-lg active mt-3 btn-custom"
                                     >
                                         Cliquer pour obtenir la synthèse de l'offre
                                     </Button>
                                </div>
                                
                            
                                <p className="mt-3">
                                    Vous pouvez télécharger ci-dessous la documentation nécessaire à la
                                    commercialisation des Groupements Forestiers et Viticoles de France
                                    Valley.
                                </p>
                                <p className="mb-3">
                                    Pour rappel, les GFV sont des produits qui ne peuvent faire l’objet
                                    de publicité et de démarchage bancaire et financier. Ces solutions
                                    d’investissement ne doivent être proposées que sur demande de vos
                                    clients, dans la limite de 150 sollicitations par produit. Nous
                                    vous demandons donc de faire signer, préalablement à la
                                    présentation des produits, un mandat de recherche (dont vous
                                    trouverez un modèle ci-dessous), et de nous signaler cette
                                    sollicitation. Prenez donc le soin de ne présenter ces éléments
                                    qu’aux personnes dont vous pensez qu’elles ont une probabilité
                                    forte de souscrire.
                                </p>
                            </>
                            )}
                            <Form.Group className="my-3">
                                <Form.Label> <strong>Choisir une solution d'investissement: </strong></Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedFond}
                                    onChange={handleFondChange}
                                    className="w-50 mx-auto form-select shadow-lg"
                                >
                                    <option value="">Sélectionnez une solution </option>
                                    {fonds.map((fond) => (
                                        <option key={fond.id} value={fond.id}>
                                            {fond.name}
                                        </option>
                                    ))}
                                </Form.Control>

                            </Form.Group>
                            {fondSelected && (
                                <Card className="my-3" ref={documentsRef}>
                                    <Card.Header>{fondSelected.name}</Card.Header>
                                    <ListGroup variant="flush">
                                        {fondDocuments.map((doc, index) => (
                                            <ListGroup.Item key={index}>
                                                <Row>
                                                    <Col className="doc-name">
                                                        <span>{doc.name}</span>
                                                    </Col>
                                                    <Col className="text-end">
                                                        <Button
                                                            className="btn-custom" 
                                                            size="sm"
                                                            onClick={() =>
                                                                downloadSingleFile(doc.path)
                                                            }
                                                        >
                                                            <BsDownload />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    <Card.Footer>
                                        {fondDocuments.length > 1 ? (
                                            <Button className="btn-custom" onClick={() => downloadAllFiles(fondId)}>
                                                <BsDownload /> Télécharger tous les documents
                                            </Button>
                                        ) : null
                                        }
                                    </Card.Footer>
                                </Card>
                            )}
                        {/* <div className="my-3" style={{ maxWidth: "560px", margin: "0 auto" }}>
                                <iframe
                                    title="video"
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/zhsi1fy7H3I"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </div>*/}
                        </Card>
                        )}
                    </Container>
                </>
                    )}
                </PageContainer>
                

    );
}

export default Fonds;
