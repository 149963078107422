import React, {useEffect, useState} from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import {getFondsByRoleWithEntranceFee, getFondsREsubscribeByRoleWithEntranceFee} from "../services/Fond";
import {checkLastSubscribeForClient, getListClientsSubOnlineByPartner, resubscribe} from "../services/Subscribe";
import {getUserById} from "../services/user";
import Spinner from 'react-bootstrap/Spinner';
import PageContainer from "../components/PageContainer";
import '../css/REsouscription.css';



function REsouscription() {
    const [fonds, setFonds] = useState([]);
    const [selectedFonds, setSelectedFonds] = useState(null);
    const [selectedDroit, setSelectedDroit] = useState(null);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isPartner, setIsPartner] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [droitsEntrees, setDroitsEntrees] = useState([]);

    const [shareNumber, setShareNumber] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [attachedFile, setAttachedFile] = useState(null);

    const [iban, setIban] = useState("");
    const [bic, setBic] = useState("");

    const [role, setRole] = useState("");

    const [fondsError, setFondsError] = useState(false);
    const [shareNumberError, setShareNumberError] = useState(false);
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [attachedFileError, setAttachedFileError] = useState(false);
    const [validationTriggered, setValidationTriggered] = useState(false);
    const [minShareNumberError, setMinShareNumberError] = useState(false);
    const [clientError, setClientError] = useState(false);
    const [droitError, setDroitError] = useState(false);



    const [ribChanged, setRibChanged] = useState(false);
    const [attachedFileRib, setAttachedFileRib] = useState(null);
    const [ribChangedError, setRibChangedError] = useState(false);
    const [isCreatingPdf, setIsCreatingPdf] = useState(false);

    const [adjustedSharePrice, setAdjustedSharePrice] = useState(0);


    useEffect(() => {
        async function getFondsLoad() {
  
            const resUser = await getUserById();
            setRole(resUser.role);

            const fondsData = await getFondsREsubscribeByRoleWithEntranceFee();
            setFonds(fondsData);
            const allLinkReSubscribesEntranceFees = fondsData.flatMap(fond => fond.reSubscribes);
            setDroitsEntrees(allLinkReSubscribesEntranceFees);

            if (resUser.role === "conseiller") {
                const clientsData = await getListClientsSubOnlineByPartner();
                setClients(clientsData);
                //je le passe a true car il est conseiller
                setIsPartner(true)
            }

            if (resUser.role === "client") {
                //check si la derniere souscription est de France Valley
                const res = await checkLastSubscribeForClient();
                setIsPartner(res.isPartner);
            }
            setIsLoading(false)
        }
        getFondsLoad();
    }, []);


    
    const isFondsValid = () => !!selectedFonds;
    const isShareNumberValid = () => shareNumber > 0;
    const isPaymentMethodValid = () => paymentMethod !== "";
    const isAttachedFileValid = () => !!attachedFile;

    const handleCreatePdf = async (event) => {
        event.preventDefault();

        if (role === "conseiller") {
            setClientError(!selectedClient);
        }

        if (isCreatingPdf) return;

        setIsCreatingPdf(true);
        setValidationTriggered(true); 
    
        if (!isFormValid()) {
            setIsCreatingPdf(false); 
            return; 
        }

        const formData = collectFormData();
        try {
            const res = await resubscribe(formData);
            if(res.status === 200) {
                window.open(res.url, "_blank");
            }
        } catch (error) {
            throw error;
        } finally {
            setIsCreatingPdf(false); 
        }
    }

    const handleClientChange = (event) => {
        const clientId = parseInt(event.target.value);
        setSelectedClient(clients.find((c) => c.id === clientId));
    };

    const collectFormData = () => {
        const formData = new FormData();

        const prixDeLaPart = adjustedSharePrice; // Utilisez le prix ajusté
        const montantTotal = shareNumber * prixDeLaPart;

        formData.append('fondId', selectedFonds.id);
        if (role === "conseiller" && droitsEntrees.length > 1 && selectedFonds ){
            formData.append('droitEntree', selectedDroit.entranceFee);
        }
        if (role === "conseiller") {
            formData.append('client', selectedClient.id);
        }
        formData.append('nbParts', shareNumber);
        formData.append('methodeDePaiement', paymentMethod);
        if (paymentMethod === "prelevement SEPA") {
            formData.append('iban', iban);
            formData.append('bic', bic);
        }
        formData.append('prixPart', prixDeLaPart);
        formData.append('montantTotal', montantTotal);
    
        // Ajouter les fichiers - Assurez-vous que 'attachments' correspond au nom attendu par le serveur
        if (attachedFile) {
            formData.append('attachments', attachedFile);
        }
        if (attachedFileRib) {
            formData.append('attachments', attachedFileRib);
        }

        return formData;
    };

    useEffect(() => {
        setDroitError(!selectedDroit);
    }, [selectedDroit]);


    useEffect(() => {
        setFondsError(!selectedFonds);
    }, [selectedFonds]);

    useEffect(() => {
        setClientError(!selectedClient);
    }, [selectedClient]);

    const handleFondsChange = (event) => {
        const fondsId = parseInt(event.target.value);
        const selectedFonds = fonds.find((f) => f.id === fondsId);

        if (selectedFonds) {
            setSelectedFonds(selectedFonds);
            const droits = selectedFonds.reSubscribes;
            setDroitsEntrees(droits);
            setSelectedDroit('');  // réinitialiser le droit sélectionné
        } else {
            setSelectedFonds('');
        }
    };

    const handleDroitChange = (event) => {
        const droitId = parseInt(event.target.value);
        const foundDroit = droitsEntrees.find((d) => d.id === droitId);

        if (foundDroit) {
            setSelectedDroit(foundDroit);
        } else {
            setSelectedDroit('');
        }
    };

    const handleShareNumberChange = (event) => {
        const newShareNumber = event.target.value;
        setShareNumber(newShareNumber);
        setShareNumberError(!newShareNumber)

    };

    const handlePaymentMethodChange = (event) => {
        const newPaymentMethod = event.target.value;
        setPaymentMethod(newPaymentMethod);
        setPaymentMethodError(!newPaymentMethod);

        if (newPaymentMethod === "prelevement SEPA") {
            setIban("");
            setBic("");
        }
    };

    const handleFileChange = (event) => {
        const newAttachedFile = event.target.files[0];
        setAttachedFile(newAttachedFile);
        setAttachedFileError(!newAttachedFile);
    };
    const handleFileRibChange = (event) => {
        const newAttachedFile = event.target.files[0];
        setAttachedFileRib(newAttachedFile);
        setRibChangedError(!newAttachedFile);
    };

    const totalPrice = shareNumber * (selectedFonds ? selectedFonds.sharePrice : 0);


    const isIbanValid = () => {
        if (paymentMethod === "prelevement SEPA") {
            const sanitizedIban = iban.replace(/\s+/g, '');
            if (sanitizedIban.length !== 27 || !/^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/.test(sanitizedIban)) {
                return "Veuillez saisir un IBAN valide.";
            }
        }
        return true;
    };
    
    

    const isBicValid = () => {
        if (paymentMethod === "prelevement SEPA") {
            if (!/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(bic.trim())) {
                return "Veuillez saisir un BIC valide.";
            }
        }
        return true;
    };



    const handleShareNumberBlur = () => {
        setMinShareNumberError(!isMinShareNumberValid());
    };

    const isMinShareNumberValid = () => {
        return !(selectedFonds && parseInt(shareNumber) < selectedFonds.shareNumberMin);

    };
    const isFormValid = () => {

        const validations = [
            isFondsValid(),
            isShareNumberValid(),
            isMinShareNumberValid(),
            isPaymentMethodValid(),
            isBicValid(),
            isIbanValid(),
            isAttachedFileValid(),
            ribChanged ? !!attachedFileRib : true,
            role === "conseiller" && droitsEntrees.length > 1 ? !!selectedDroit : true,
            role === "conseiller" ? !!selectedClient : true,
        ];
    
       
        const formIsValid = validations.every(Boolean); 
       
        
        setValidationTriggered(true);
        setFondsError(!isFondsValid());
        setShareNumberError(!isShareNumberValid());
        setMinShareNumberError(!isMinShareNumberValid());
        setPaymentMethodError(!isPaymentMethodValid());
        const ibanValid = isIbanValid();
        if (ibanValid !== true) {
            alert(ibanValid);
            return;
        }

        const bicValid = isBicValid();
        if (bicValid !== true) {
            alert(bicValid);
            return;
        }
        setAttachedFileError(!isAttachedFileValid());
        setRibChangedError(ribChanged ? !attachedFileRib : false);
        setMinShareNumberError(!isMinShareNumberValid());
        setDroitError(role === "conseiller" && droitsEntrees.length > 1 ? !selectedDroit : false);
        setClientError(role === "conseiller" ? !selectedClient : false);

        return formIsValid;
    };
    

    function formatPrice(value) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
    }

        // Fonction pour calculer le prix ajusté de la part
        const calculateAdjustedSharePrice = (selectedFonds, selectedDroit) => {
            if (!selectedFonds) return;
        
            const basePrice = selectedFonds.sharePrice;
            let adjustedPrice = basePrice;
    
            if (selectedDroit.entranceFee) {
                const entranceFeePercentage = parseFloat(selectedDroit.entranceFee.replace('%', '')) / 100;
                adjustedPrice += basePrice * entranceFeePercentage;
                console.log(adjustedPrice);
            }
        
            setAdjustedSharePrice(adjustedPrice);
        };
        
    
        // Effet pour recalculer le prix ajusté de la part chaque fois que le fonds sélectionné ou le droit d'entrée change
        useEffect(() => {
            calculateAdjustedSharePrice(selectedFonds, selectedDroit);
        }, [selectedFonds, selectedDroit]);
    
    return (
       <PageContainer isLoading={isLoading}>
           {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
            <>
                <Container className="my-5">
                    <h1 className="text-center mb-5" style={{color: '#243E45'}}>REsouscription</h1>
                    {!isPartner ? (
                            <Row>
                                <Col>
                                    <Card className="shadow my-4">
                                        <Card.Body>
                                            <h5 className="text-center mb-3" style={{ color: "#243E45" }}>
                                                Information importante
                                            </h5>
                                            <p className="text-center">
                                                Vous avez été conseillé par un CGP (Conseiller en Gestion de Patrimoine), une Banque Privée ou un Family Office.
                                            </p>
                                            <p className="text-center">
                                                N'hésitez pas à le contacter pour qu'il vous présente l'offre actuelle de Fonds Forestiers et Viticoles de France Valley et pour qu'il vous transmette un lien Internet permettant de souscrire 100% en ligne.
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                    ) : (
                        <Row>
                            <Col lg={6}>
                                <Card className="shadow">
                                    <Card.Body>
                                        <Form>

                                            {role === "conseiller" && (
                                                <Form.Group controlId="clients" className="mb-3">
                                                    <Form.Label>Vos Clients</Form.Label>
                                                    <Form.Control as="select" className={"form-select"} value={selectedClient?.id} onChange={handleClientChange} isInvalid={validationTriggered && clientError} required>
                                                        <option value="">Sélectionnez un client</option>
                                                        {clients.map((client) => (
                                                            <option key={client.id} value={client.id}>
                                                                {client.lastName} {client.firstName} ({client.civility})
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}
                                            <Form.Group controlId="fonds"className="mb-3">
                                                <Form.Label>Les Fonds</Form.Label>
                                                <Form.Control as="select" className={"form-select"} value={selectedFonds?.id} onChange={handleFondsChange}  isInvalid={validationTriggered && fondsError} required>
                                                    <option value="">Sélectionnez un fonds</option>
                                                    {fonds.map((fond) => (
                                                        <option key={fond.id} value={fond.id}>
                                                            {fond.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                            {role === "conseiller" && droitsEntrees.length > 1 && selectedFonds && (
                                                <Form.Group controlId="droits" className="mb-3">
                                                    <Form.Label>Les Droits d'entrée</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        className="form-select"
                                                        value={selectedDroit?.id}
                                                        onChange={handleDroitChange}
                                                        isInvalid={validationTriggered && droitError}
                                                        disabled={!selectedFonds}
                                                        required
                                                    >
                                                        <option value="">Sélectionnez un droit d'entrée</option>
                                                        {droitsEntrees.map((droitEntree, index) => (
                                                            <option key={index} value={droitEntree.id}>
                                                                {droitEntree.entranceFee}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}
                                            <Form.Group className="mb-3" controlId="shareNumber">
                                                <Form.Label>Nombre de parts</Form.Label>
                                                <Form.Control type="number" value={shareNumber} onChange={handleShareNumberChange} onBlur={handleShareNumberBlur} isInvalid={validationTriggered && (shareNumberError || minShareNumberError)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    {minShareNumberError ? `Le nombre minimum de parts pour ce fond est de ${selectedFonds ? selectedFonds.shareNumberMin : 0}` : "Veuillez saisir un nombre de parts valide." }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Row className="mb-3" >
                                                <Col>
                                                    <Form.Label>Prix de la part:</Form.Label>
                                                    <Form.Control type="text" value={formatPrice(adjustedSharePrice)} readOnly />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Montant total:</Form.Label>
                                                    <Form.Control type="text" value={formatPrice(shareNumber * adjustedSharePrice)} readOnly />
                                                </Col>
                                            </Row>
                                            <Form.Group className="mb-3" controlId="paymentMethod">
                                                <Form.Label>Moyen de paiement</Form.Label>
                                                <Form.Control as="select" className={"form-select"} value={paymentMethod} onChange={handlePaymentMethodChange}  isInvalid={validationTriggered && paymentMethodError} required>
                                                    <option value="">Sélectionnez un moyen de paiement</option>
                                                    <option value="prelevement SEPA">Prélèvement SEPA</option>
                                                    <option value="cheque">Chèque</option>
                                                    <option value="virement">Virement bancaire</option>
                                                </Form.Control>
                                                
                                            </Form.Group>
                                            {paymentMethod === "prelevement SEPA" && (
                                                <>
                                                 <Form.Group className="mb-3" controlId="iban">
                                                    <Form.Label>IBAN</Form.Label>
                                                    <Form.Control type="text" value={iban} name="iban" onChange={(e) => setIban(e.target.value)} isInvalid={!isIbanValid()} required={paymentMethod === "prelevement SEPA"}/>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="bic">
                                                    <Form.Label>BIC</Form.Label>
                                                    <Form.Control type="text" value={bic} name="bic" onChange={(e) => setBic(e.target.value)} isInvalid={!isBicValid()} required={paymentMethod === "prelevement SEPA"} />
                                                </Form.Group>
                                                </>
                                                )}
                                           

                                            <Form.Group className="mb-3">
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="J'ai changé de RIB" 
                                                    checked={ribChanged} 
                                                    onChange={() => setRibChanged(!ribChanged)} 
                                                />
                                            </Form.Group>

                                            {ribChanged && (
                                                <Form.Group className="mb-3" controlId="newRib">
                                                    <Form.Label>Nouveau RIB</Form.Label>
                                                    <Form.Control type="file" onChange={handleFileRibChange} isInvalid={validationTriggered && ribChangedError} required={ribChanged}/>
                                                </Form.Group>
                                            )}

                                            <Form.Group className="mb-3" controlId="attachedFile">
                                                <Form.Label>Joindre un justificatif de domicile</Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} isInvalid={validationTriggered && attachedFileError}  required/>
                                            </Form.Group>
                                            <Form.Group className="mb-3 text-center">
                                                <Button type="submit" className="btn-custom mb-2" onClick={handleCreatePdf} disabled={isCreatingPdf}>
                                                {isCreatingPdf ? "Création en cours..." : "Créer un PDF"}
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="shadow">
                                    <Card.Body>
                                        <a 
                                            href="/modifSouscription" 
                                            style={{
                                                color: 'red',
                                                fontSize: '120%',
                                                fontWeight: 'bold',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            Cliquez si des informations de souscription ont changé
                                        </a>
                                        {role === "conseiller" ? (
                                            <>
                                                <h5 className="my-2">Vidéo explicative de la REsouscription de vos clients :</h5>
                                                <div className="my-3" style={{ maxWidth: "560px", margin: "0 auto" }}>
                                                    <iframe
                                                        title="video"
                                                        width="100%"
                                                        height="315"
                                                        src="https://extranet.france-valley.com/REsouscriptionPartenaires.mp4"
                                                        frameBorder="0"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            </>
                                            ) : (
                                                <>
                                                 <div className="my-3" style={{ maxWidth: "560px", margin: "0 auto" }}>
                                                    <iframe
                                                        title="video"
                                                        width="100%"
                                                        height="315"
                                                        src="https://extranet.france-valley.com/REsouscriptionAssocies.mp4"
                                                        frameBorder="0"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                                    <h5>Quel Fonds choisir ?</h5>
                                                    <ul>
                                                        {fonds.map((fond) => (
                                                            <li key={fond.id}>
                                                                <strong>{fond.name}</strong> : {fond.description}
            
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}                           
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </>
            )}
       </PageContainer>
    );
}

export default REsouscription;
