import React, {useEffect, useState} from "react";
import { Card, Container } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import PageContainer from "../components/PageContainer";
import { useLocation } from "react-router-dom";
import { getProduitAGById } from "../services/ProduitAG";
import '../css/VoteAG.css'
import { getVoteAGByProduitAGId } from "../services/VoteAG";


function VoteAG() {
    const [isLoading, setIsLoading] = useState(true);
    const [produitAG, setProduitAG] = useState([]);
    const [expandedResolutions, setExpandedResolutions] = useState([]);
    const [votes, setVotes] = useState([]);
    const [pouvoirPresidentTotal, setPouvoirPresidentTotal] = useState(false);

    const location = useLocation();
    const produitAGId = location.state?.produitAGId;

    useEffect(() => {
        async function getProduitsAG() {
            const res = await getProduitAGById(produitAGId);
            const resVotes = await getVoteAGByProduitAGId(produitAGId);
            setProduitAG(res);
            setVotes(resVotes.votes);
            setPouvoirPresidentTotal(resVotes.pouvoirPresidentTotal);
            setIsLoading(false);
        }
        getProduitsAG();
    }, []);

    const toggleResolution = (resolutionId) => {
        setExpandedResolutions(prev => 
            prev.includes(resolutionId) ? prev.filter(id => id !== resolutionId) : [...prev, resolutionId]
        );
    };
    
    return (
       <>
           {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
            <>
                <Container className="my-5">
                <h1 className="text-center page-title">Vote sur le produit {produitAG.nom}</h1>
                {pouvoirPresidentTotal ? (
                    <Card className="info-card">
                        <Card.Body>
                            <Card.Title className="text-center">Vote par Internet</Card.Title>
                            <Card.Text>
                                Vous avez donné pouvoir au Président de l'Assemblée Générale afin qu'il vote favorablement aux résolutions agréées par la Société de Gestion et défavorablement à toute autre résolution.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                ) : (
                    <Card className="info-card">
                        <Card.Body>
                            <Card.Title className="text-center">Vote par internet</Card.Title>
                            <Card.Text><strong>Vous avez choisi de voter par internet.</strong>
                                <br/>
                                <br/>
                                <strong>Nous avons pris en compte votre vote et nous vous remercions de votre participation.</strong>
                                <br/>
                                <br/>
                                {votes.length > 0 && (
                                <p>En cas d'amendement ou de résolutions nouvelles lors de l'Assemblée Générale, {votes[0].voteAmendement === 'Procuration au Président' ? 'vous avez choisi de donner procuration au Président de l\'Assemblée Générale de voter en votre nom.' : 'vous avez choisi de vous abstenir.'}</p>
                                )}
                            </Card.Text>
                                <Card className="shadow mb-3">
                                    <Card.Header>
                                        <strong>  <p>Vous avez voté aux résolutions :</p></strong>
                                    </Card.Header>
                                        {votes.map(vote => (
                                            <Card key={vote.resolutionAG.id}>
                                                <Card.Header className="header-resolution" onClick={() => toggleResolution(vote.resolutionAG.id)}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span> <strong>{expandedResolutions.includes(vote.resolutionAG.id) ? '-' : '+'} {vote.resolutionAG.nom}</strong></span>
                                                        <span className={`choice-box ${vote.choix === 'Pour' || vote.choix === 'Pouvoir au Président' ? 'choice-box-green' : 'choice-box-red'}`}>
                                                            {vote.choix}
                                                        </span>
                                                    </div>
                                                </Card.Header>
                                                {expandedResolutions.includes(vote.resolutionAG.id) && (
                                                    <Card.Body>
                                                        <Card.Text>{vote.resolutionAG.texte}</Card.Text>
                                                    </Card.Body>
                                                )}
                                            </Card>
                                        ))}
                                </Card>
                        </Card.Body>
                        {votes.length > 0 && (
                            <Card.Footer className="text-muted text-center">
                                <Card.Text>
                                    Date du vote : {new Date(votes[0].date).toLocaleString('fr-FR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    }).replace(':', 'H')}. Votre empreinte : {votes[0].empreinte}.
                                </Card.Text>
                            </Card.Footer>
                        )}
                    </Card>
                )}
                </Container>
            </>
            )}
       </>
    );
}

export default VoteAG;
