import React, {useEffect, useState} from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import PageContainer from "../components/PageContainer";
import { getProduitsAGByClient } from "../services/ProduitAG";
import {useNavigate} from "react-router-dom";



function AG() {
    const [isLoading, setIsLoading] = useState(true);
    const [produitsAG, setProduitsAG] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
        async function getProduitsAG() {
            const res = await getProduitsAGByClient();
            setProduitsAG(res);
            setIsLoading(false);
        }
        getProduitsAG();
    }, []);

    const handleNavigate = (produitAGId, aVote) => {
        if (aVote) {
            navigate(`/voteAG`, { state: { produitAGId } });
        } else {
            navigate(`/produitAG`, { state: { produitAGId } });
        }
    };
    

    return (
       <PageContainer isLoading={isLoading}>
           {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
            <>
                <Container className="my-5">
                    <h1 className="text-center mb-5" style={{color: '#092A6C'}}>Mes Assemblées Générales</h1>
                   <Card className="mb-3 shadow">
                        <Card.Body>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered table-hover table-sm">
                                            <thead>
                                            <tr className="text-center">
                                                <th>Produit</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {produitsAG.map((produitAG) => (
                                                    <tr key={produitAG.id}>
                                                        <td className="text-center">{produitAG.nom}</td>
                                                        <td className="text-center">
                                                        <Button 
                                                            className="btn btn-primary btn-sm" 
                                                            onClick={() => handleNavigate(produitAG.id, produitAG.aVote)}>
                                                            {produitAG.aVote ? 'À voté' : 'Voter'}
                                                        </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
            </>
            )}
       </PageContainer>
    );
}

export default AG;
